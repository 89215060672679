import { useEffect, useMemo, useState } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useActiveCategory } from '@hmn/rtl-web-core/hooks'

import { AnimatedMenuItem } from './components'
import styles from './Menu.style'
import { menuVariants } from './Menu.variants'

const MenuStyled = styled.div(props => ({ ...styles(props) }))

function Menu({ links, variant, gtmData, higlightActive, title, className, maxColumns, ...rest }) {
    const uid = useUIDSeed()

    const [openIndex, setOpenIndex] = useState(-1)
    const [activeCategory, activeSubcategory] = useActiveCategory()

    const handleIndexChange = index => {
        setOpenIndex(index === openIndex ? -1 : index)
    }

    const filteredLinks = useMemo(() => links?.filter(link => link.title) || [], [links])
    const linksInColumns = useMemo(
        () =>
            filteredLinks?.reduce(
                (acc, curr, index) => {
                    curr.color = curr.href.includes('https://zena.net.hr') ? '#BF2175' : curr.color
                    acc[acc.length - 1].push({ ...curr, index })
                    if ((curr?.items?.length > 0 && acc.length < maxColumns) || variant === menuVariants.HEADER) {
                        acc.push([])
                    }
                    return acc
                },
                [[]]
            ),
        [filteredLinks, maxColumns]
    )

    useEffect(() => {
        if (variant === 'navigation') {
            setOpenIndex(filteredLinks?.map(({ href }) => href === (activeSubcategory ?? activeCategory)).indexOf(true))
        }
    }, [filteredLinks, activeCategory, variant])

    if (!filteredLinks || !linksInColumns) {
        return null
    }

    return (
        <MenuStyled variant={variant} className={className} {...rest}>
            {title && <span className="Menu_title">{title}</span>}
            <div className="Menu_container">
                {linksInColumns.map(
                    (column, colIndex) =>
                        column?.length > 0 && (
                            <div className="Menu_group" key={uid(`${column.length} ${colIndex}`)}>
                                {column.map((link, index) => (
                                    <AnimatedMenuItem
                                        className="Menu_item"
                                        link={link}
                                        key={uid(`${link.href || link.title}${index}`)}
                                        gtmData={gtmData}
                                        higlightActive={higlightActive}
                                        open={openIndex === link.index}
                                        variant={variant}
                                        clickFunction={() => {
                                            handleIndexChange(link.index)
                                        }}
                                    />
                                ))}
                            </div>
                        )
                )}
            </div>
        </MenuStyled>
    )
}

Menu.propTypes = {
    links: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    variant: PropTypes.oneOf([...Object.values(menuVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    higlightActive: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    maxColumns: PropTypes.number
}

Menu.defaultProps = {
    links: undefined,
    variant: menuVariants.HEADER,
    gtmData: undefined,
    higlightActive: true,
    className: undefined,
    title: undefined,
    maxColumns: 6
}

export { menuVariants }
export default withErrorBoundary(Menu, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Menu]: ', error, componentStack)
    }
})
